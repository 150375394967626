<template>
  <div class="recommendation-card">
    <div class="prod-image-disp" :style="{ 'background-image': 'url(' + helpers.getImage(data.PrimaryImage, 'PRODUCT') + ')' }"></div>
    <div class="prod-cont-content">
      <div style="display: flex; justify-content: center; align-items: center">
        <div style="padding-right: 15px">
          <h1>{{ helpers.getAttribute(data, $t.getLanguage(), "Name") }}</h1>
          <f7-button fill raised @click="onViewProduct(data.ProductKey)">{{ helpers.formatPrice(data?.ProductPrice, data?.CurrencyCode) }}</f7-button>
        </div>
        <!-- <div class="start-review-cont">
  				<h3>{{ data.Rating }}</h3>
	            <span>{{ data.TotalRating }} {{ $t.getTranslation("LBL_RATINGS") }}</span>
	            <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="data.Rating || 0" :read-only="true"></star-rating>
  			</div> -->
      </div>
      <h1 style="margin-top: 30px">{{ data?.Name }}</h1>
      <p>{{ data?.Description }}</p>
    </div>

    <!-- <div class="reviews" v-if="data.TopReview">
      <h1 class="title">{{ $t.getTranslation("LBL_REVIEWS") }}</h1>
      <div class="review">
        <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="data.TopReview.Rating" :read-only="true"></star-rating>
        <p>{{ data.TopReview.Content }}</p>
        <div class="profile">
          <img :src="$h.getImage(data.TopReview?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
          <h3>{{ data.TopReview.FirstName }}</h3>
        </div>
      </div>
    </div> -->
    <!--  https://cocoho-ph.atlassian.net/browse/PW-201?atlOrigin=eyJpIjoiZmY0NjAwNjVhM2NmNDg4Y2JkMDU1Y2RlNmNmMmQwMDIiLCJwIjoiaiJ9
    Cathy want to hide the rating. -->
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { helpers } from "@/utils/helpers.js";

import { f7 } from "framework7-vue";

// import StarRating from '@/components/rating/star-rating.vue'

export default defineComponent({
  name: "RecommendationCardComponent",
  components: {},
  props: {
    data: Object,
    f7router: Object,
    f7route: Object,
  },
  setup(props) {
    const $f7router = f7.views.get("#main-view").router;
    const onViewProduct = (ProductKey) => {
      // $f7router.navigate({ name: "productViewPage", params: { code: ProductKey } });
      props.f7router.navigate({ name: "productViewPage", params: { code: ProductKey } });
    };
    return { helpers, onViewProduct };
  },
});
</script>
